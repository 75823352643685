import React from "react";

class Gallery extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <h1>Gallery Page</h1>
        );
    }
}

export default Gallery;