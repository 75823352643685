import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Detail';
import Gallery from "./components/Gallery";
import Project from "./components/Project";
import object_get from "./lib/object_get";
import {Col, Container, Row} from "react-bootstrap";
import React, {useState} from "react";
import {animated, useSpring, useTransition} from "react-spring";
function App(props) {
    // let MENUS = ['Contact', 'Gallery', 'Blog', 'Projects', 'Home']
    let MENUS = [{
        'name': 'Contact',
        'key': 'contact',
        'page': <h1>Contact</h1>
    }, {
        'name': 'Gallery',
        'key': 'gallery',
        'page': <Gallery/>
    }, {
        'name': 'Blog',
        'key': 'blog',
        'page': <h1>Blog</h1>
    }, {
        'name': 'Projects',
        'key': 'projects',
        'page': <Project/>
    }, {
        'name': 'Home',
        'key': 'home',
        'page': <Home/>,
        'url': ''
    }]
    const style = useSpring({
        delay: 6000,
        loop: false,
        from: {
            y: 400,
            opacity: 0,
            filter: 'blur(150px)'
        },
        to: {
            y: 0,
            opacity: 1,
            filter: 'blur(0px)'
        },
    });
    let [loaded, setLoaded] = useState(false);
    const messageEntryStyle = useSpring({
        delay: 700,
        loop: false,
        from: {
            x: -500,
            y: 0,
            filter: 'blur(150px)',
            opacity: 0
        },
        to: [{
            x: 0,
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            // margin: "200px",
            // position: "fixed"
        },{
            delay: 3500,
            x: -800,
            y: 0,
            // opacity: 0,
            filter: 'blur(150px)',
            exit: 1,
        // margin: "200px",
        // position: "fixed"
        }],
        onRest: () => {
            setLoaded(true);
            console.log("Data loaded successfully.... ", loaded);
        }
    })

    const newStyle = useSpring({
        delay: 6000,
        loop: false,
        from: {
            x: -500,
            y: 0,
            filter: 'blur(150px)',
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            // margin: "200px",
            // position: "fixed"
        },
    })

    return (
       <div className="App" style={{
           // marginTop: "-150px",
           // ...newStyle
       }} after={{
           // margin: "200px"
       }}>
           <animated.div style={{
               width: "100%",
               height: "100%",
               ...messageEntryStyle,
               display: loaded === true ? "none": "inherit",
               // backgroundColor: messageEntryStyle.exit.interpolate((ex) => ex === false ? "red": "black")
               // ...messageExitStyle
           }}>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <animated.img
                   src={'./gif/greeting_stick.gif'}
                   // width={'300px'}
                   // height={'450px'}
                   style={{
                       'clip': 'rect(0,auto,180px,0)',
                       "z-index": 10,
                       'opacity': 1,
                }}
               />
               <animated.h1 style={{
               }}>Welcome. Give me a moment to load the site... </animated.h1>
           </animated.div>
           <BrowserRouter>
               <NavBar menu = {MENUS} style={{
                   ...newStyle
               }}/>
               {loaded === true ?
                   <animated.div style={{
                       marginTop: "150px",
                       ...style
                   }}>

                       <Switch>
                           {MENUS.map((item)=>(
                               <Route key={item.key} path={'/'+ object_get(item, 'url', item.key)}>
                                   {object_get(item, 'page', 'hello')}
                               </Route>
                           ))}

                       </Switch>
                   </animated.div>
                   : <div/>}

           </BrowserRouter>
        </div>
    );
}

export default App;
