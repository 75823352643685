import React from "react";

class Card extends React.Component{

    constructor(props) {
        super(props);
        this.state ={
            data: props.data,
            defaultThumbnail: "https://raw.githubusercontent.com/rajshrestha86/kmeans-clusterize/master/img/corr-map.png"
        }

    }

    render() {
        const inlineLi = {
            display: "inline",
            borderRadius: "15px",
            border: "1px solid black",
            background: "black",
            color: "white",
            marginRight: "5px",
            padding: "3px 5px 3px 5px"
        }
        return(
            <div style={{
                "overflow": "hidden",
                "width": "80%",
                // "border": "1px solid black",
                boxShadow: "0 0 20px rgba(0,0,0,.12)",
                marginBottom: "25px"

            }}>
                <a href={this.state.data.projectLink} target={"_blank"}>
                <div style={{
                    // "width": "40%",
                    // "border": "5px solid black",
                    // "position": "relative",
                    "overflow": "hidden"
                }}>

                    <div style={{
                        "float": "left",
                        "width": "30%",
                        "height": "280px",
                        // "border": "1px solid black",
                        background: "#e9e9e9"
                    }}>
                        <img src={this.state.defaultThumbnail === undefined || this.state.defaultThumbnail == ""? this.state.defaultThumbnail : this.state.data.thumbnail } style={{
                            objectFit: "cover",
                            objectPosition: "center",
                            width: "auto",
                            maxWidth: "100%",
                            height: "400px"
                        }}/>
                    </div>
                    <div style={{
                        "float": "left",
                        "height": "280px",
                        "width": "65%",
                        "padding": "0 1% 0% 1%",
                        "overflow": "auto",
                        marginLeft: "10px"
                        // "border": "2px solid red",
                        // "position": "relative"
                    }}>
                        <ul style={{
                            textAlign: "left",
                            marginBottom: "10px",
                            marginLeft: "-40px",
                            marginTop: "15px",
                            position: "block",
                            fontSize: "15pt"
                        }}>
                            {this.state.data.technologies !== undefined ? this.state.data.technologies.map((each) => <li className={"inlineLi"}>{each}</li> ): <br/>}
                            {/*<li style={inlineLi}>C</li>*/}
                            {/*<li style={inlineLi}>KMeans</li>*/}
                            {/*<li style={inlineLi}>MPI</li>*/}
                            {/*<li style={inlineLi}>CUDA</li>*/}
                        </ul>

                            <p style={{
                                textAlign: "left",
                                width: "100%",
                                fontWeight: "bold",
                                // fontSize: "10",
                                margin: 0

                            }}>{this.state.data.name}</p>
                        <p style={{
                            textAlign: "justify",
                            marginTop: 2,
                            fontSize: "20pt"
                        }}>
                            {this.state.data.description}
                        </p>
                    </div>
                </div>
            </a>
            </div>


        );
    }
}

export default Card;