import React from 'react';
import {animated, useSpring} from "react-spring";
import {Col, Container, Row} from 'react-bootstrap';
import EducationSection from "./CV/EducationSection";
import ExperienceSection from "./CV/ExperienceSection";
import ReactGA from 'react-ga4';

function AnimatedBG(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const style = useSpring({
        delay: 800,
        loop: false,
        from: {x: -400, filter: 'blur(150px)'},
        to: {x: 0, filter: 'blur(2px)', opacity: 0.2},
    });
    const waving_style = useSpring({
        delay: 2000,
        // loop: true,
        // config: { duration: 10000 },
        from: {x: -10, filter: 'blur(200px)', opacity: 0},
        to: {x: 0, filter: 'blur(1px)', opacity: 0.2},
    });

    const waving_style_invisible = useSpring({
        delay: 7000,
        // loop: true,
        config: {duration: 2000},
        from: {
            filter: 'blur(1px)'
        },
        to: {
            filter: 'blur(100px)'
        },
    });

    const walking_style = useSpring({
        delay: 200,
        // loop: true,
        config: {duration: 12000},
        to: {x: 1200, opacity: 0},
        from: {x: -250, opacity: 0.2},
    });

    return <div>
        <animated.img
            src={'./png/home_bg.png'}
            width={'300px'}
            height={'450px'}
            style={{
                'margin-top': "550px",
                'position': 'fixed',
                'top': 0,
                'left': 0,
                "z-index": -1,
                // 'opacity': 0.1,
                ...style

            }}
        />
        <div>
           {/*<animated.img*/}
           {/*      src={'./gif/walking_stick.gif'}*/}
           {/*     // width={'300px'}*/}
           {/*     // height={'450px'}*/}
           {/*     style={{*/}
           {/*         // 'background-position': 'bottom 500px' ,*/}
           {/*         'position': 'fixed',*/}
           {/*         'bottom': 0,*/}
           {/*         'left': 0,*/}
           {/*         'clip': 'rect(0,auto,250px,0)',*/}
           {/*         'margin-bottom': '-65px',*/}
           {/*         'opacity': 0.2,*/}
           {/*         ...walking_style*/}
           {/*         // ...style*/}

           {/*     }}*/}
           {/* />*/}

            {/*<animated.img*/}
            {/*    src={'./gif/greeting_stick.gif'}*/}
            {/*    // width={'300px'}*/}
            {/*    // height={'450px'}*/}
            {/*    style={{*/}
            {/*        // 'background-position': 'bottom 500px' ,*/}
            {/*        'position': 'fixed',*/}
            {/*        'top': 100,*/}
            {/*        'right': 0,*/}
            {/*        'clip': 'rect(0,auto,180px,0)',*/}
            {/*        "z-index": 10,*/}
            {/*        // 'margin-bottom': '-90px',*/}
            {/*        'opacity': 0,*/}
            {/*        ...waving_style,*/}
            {/*        ...waving_style_invisible*/}
            {/*        // ...style*/}

            {/*    }}*/}
            {/*/>*/}

        </div>

    </div>
}

class Home extends React.Component {

    componentDidMount() {
        ReactGA.initialize('G-39BJJVLV06');
        ReactGA.send({ hitType: "pageview", page: "/" });
    }

    render() {
        return (
            <Container fluid style={{}}>
                <AnimatedBG/>
                <Row>

                    <Col md={5} lg={3} style={{
                        'float': "left",
                        // position: "fixed",
                    }}>
                        <div style={{
                            marginLeft: "10%",
                            // position: "fixed",
                            // 'width': '300px',
                        }}>
                            <img src={'./img/profile.jpeg'} style={{
                                'width': '100%',
                                'height': 'auto',
                                borderRadius: "25px 25px 0px 0px",
                            }}/>
                            <div style={{
                                border: "1px solid black",
                                padding: 0,
                                // background: "#e9e9e9"
                            }}>
                                <h3 className={"cvHeader"}>Raj Shrestha</h3>
                                <h4 className={"cvHeader"}>MS @Utah State University</h4>
                                <p className={"cvHeader"}>raj.shrestha86@outlook.com</p>
                            </div>
                        </div>

                    </Col>
                    {/*<Col lg={3} style={{*/}
                    {/*    // position: "fixed"*/}
                    {/*}}></Col>*/}
                    <Col md={7}  lg={8}>
                        <div style={{
                            "margin": "40px",
                        }}>
                            <p style={{
                                textAlign: "justify"
                            }}>Hi ! I am Raj Shrestha. Welcome to my little space on the Internet. You can learn about
                                me and my
                                work here. I am currently pursuing my
                                Masters degree in Computer Science from Utah State University. I am currently living in
                                Logan,
                                Utah. I am originally from a beautiful South Asian country, Nepal. Prior to joining USU,
                                I
                                worked as a software engineer in the startup based company in Nepal. I have 5+ years of
                                experience in software development. I am particularly interested in distributed systems,
                                software
                                architecture, and system design. You can learn more about my work, experiences, and
                                projects
                                that I worked on here.</p>
                            <hr/>
                            <Row>
                                <h2 style={{
                                    "float": "left",
                                    textAlign: "left",
                                    // textDecoration: "underline",
                                    marginBottom: "15px"
                                }}>Education</h2>
                            </Row>
                            <Row>
                                <EducationSection data={{
                                    inst: "Utah State University",
                                    address: "Logan, Utah",
                                    degree: "MS in Computer Science",
                                    start: 2021,
                                    graduation: 2022
                                }}/>

                                <br/>
                                <EducationSection data={{
                                    inst: "Institute of Engineering, Pulchowk Campus, TU",
                                    address: "Kathmandu, Nepal",
                                    degree: "Bachelor of Engineering in Computer Engineering",
                                    start: 2014,
                                    graduation: 2018
                                }}/>
                            </Row>
                            <hr/>
                            <Row>
                                <h2 style={{
                                    "float": "left",
                                    textAlign: "left",
                                    // textDecoration: "underline",
                                    marginBottom: "15px"
                                }}>Publications</h2>
                            </Row>
                            <Row>
                                <ul style={{
                                    textAlign: "justify",
                                    marginLeft: "40px",
                                    marginRight: "40px",
                                    fontSize: "30px",
                                    fontStyle: "italic",
                                }}>
                                    <li>Raj Shrestha, Juho Leinonen, Albina Zavgorodniaia, Arto Hellas, and John Edwards. Pausing While Programming: Insights From Keystroke Analysis. In ACM International Conference on Software Engineering (ICSE), Software Engineering Education and Training (SEET) track. 2022. </li>
                                    <li>Raj Shrestha, Juho Leinonen, Arto Hellas, Petri Ihantola, and John Edwards. CodeProcess Charts: Visualizing the Process of Writing Code. Twenty-Fourth Australasian Computing Education Conference (ACE). 2022.</li>
                                    <li>Albina Zavgorodniaia, Raj Shrestha, Juho Leinonen, Arto Hellas, and John Edwards. Morning or Evening? An Examination of Circadian Rhythms of CS1 Students. ACM International Conference on Software Engineering (ICSE), Joint Track on Software Engineering Education and Training (JSEET). Madrid, Spain. 2021.</li>
                                </ul>
                            </Row>
                            <hr/>
                            {/*Experiences*/}
                            <Row>
                                <h2 style={{
                                    "float": "left",
                                    textAlign: "left",
                                    // textDecoration: "underline",
                                    marginBottom: "15px"
                                }}>Experience</h2>
                            </Row>
                            <Row>
                                <ExperienceSection lastDivider={true} data={{
                                    inst: "Utah State University",
                                    address: "Logan, Utah",
                                    positions: [{
                                        "post": "Graduate Research Assistant",
                                        "start": "Jan, 2021",
                                        "end": "Present",
                                        "tasks": [
                                            "Analyzing students programming patterns through Keystroke analysis and determining effective pedagogy.",
                                            "Designed, built and deployed ETL pipeline to parse keystroke data and web application called CodeProcess to visualize code changes on assignments over keystroke snapshots of CS1 students using Python, React and D3.",
                                            "Generating students' circadian profiles using data visualization and K-means clustering."

                                        ]

                                    }, {
                                        "post": "Graduate Teaching Assistant (CS2, JAVA)",
                                        "start": "August, 2021",
                                        "end": "December, 2021",
                                        "tasks": [
                                            "Worked as a Teaching Assistant for CS2 Introduction to JAVA course.",
                                            "Graded student's assignment and projects for the course.",
                                            "Mentored and helped students to learn core programming concepts in JAVA."
                                        ]
                                    }],
                                }}/>

                                <ExperienceSection lastDivider={true} data={{
                                    inst: "Gokyo Labs",
                                    address: "Lalitpur, Nepal",
                                    positions: [{
                                        "post": "Consulting Software Engineer",
                                        "start": "Sep, 2019",
                                        "end": "Dec, 2020",
                                        "tasks": [
                                            "Deployment of applications and worked on debugging and automating deployment scripts.",
                                            "Worked as a team mentor and team lead to train junior developers and DevOps engineers."
                                        ]

                                    }],
                                }}/>

                                <ExperienceSection lastDivider={false} data={{
                                    inst: "RARA Labs",
                                    address: "Lalitpur, Nepal",
                                    positions: [{
                                        "post": "Consulting Software Engineer",
                                        "start": "Sep, 2018",
                                        "end": "Dec, 2020",
                                        "tasks": [
                                            "Designed and built back-end architecture for a no-code (JSON Configurabe) micro-serviced data  pipeline used for data warehouse, business intelligence and automated data reconciliation using Python (flask, gevent), Redis and Postgres as a team lead.",
                                            "Designed and built multiple RESTful services using Python (Flask), Golang, Postgres, GCP and AWS. ",
                                            "Worked on Golang to create a distributed CLI app to configure hundreds of virtual machines using SSH, gRPC, Protobuf, and Go-routines at once. (Used to configure multiple machines with similar configurations or applying patches).",
                                            "Designed and built in-house libraries to accelerate API development time and process."

                                        ]

                                    }],
                                }}/>

                            </Row>
                            {/*<hr/>*/}





                        </div>

                    </Col>
                    <Col xl lg={1}></Col>


                </Row>

            </Container>
        )
    }

}

export default Home;