import React from "react";
import Card from "./Card/card";
import axios from "axios";
import BASE_URL from "../config";


class Project extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            'data': [],
            'technologies': []
        }

        this.getCards = this.getCards.bind(this);
        this.getTechStacks = this.getTechStacks.bind(this);
    }

    componentDidMount() {

        this.setState({
            'data': [
                {
                    "id": 1,
                    "name": "Clusterize",
                    "description": "Implementation of K-Means clustering algorithm from scratch. Parallelization of clustering algorithm across multiple nodes using OpenMP and MPI to reduce clustering time on a huge dataset. Also performance analysis of multiple approaches used and their comparison. ",
                    "detailView": false,
                    "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/kmeans-clusterize/master/img/corr-map.png",
                    "projectLink": "https://github.com/rajshrestha86/kmeans-clusterize",
                    "technologies": ["C", "KMeans", "MPI", "CUDA"]
                },

                {
                    "id": 2,
                    "name": "MPI Histogram",
                    "description": "A program developed using MPI for distributed computation of Histogram for large data and their performance anaysis on multi-core systems. The program is written in C and uses SPMD architecture to fork processes and compute histogram.",
                    "detailView": false,
                    "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/kmeans-clusterize/master/img/corr-map.png",
                    "projectLink": "https://github.com/rajshrestha86/mpi-histo",
                    "technologies": ["C", "Distributed Computing", "MPI", "HPC"]
                },
                {
                    "id": 3,
                    "name": "CodeProcess",
                    "description": "CodeProcess is a software visualization tool that is designed to give the viewer an immediate assessment of the general characteristics of the process used to develop a piece of software. It features inter-active controls for the user to analyze details of the process. It is a web application developed using Python, D3 and React JS. ",
                    "detailView": false,
                    "thumbnail": "https://raw.githubusercontent.com/EdwardsLabUSU/CodeProcess-API/main/img/visualization.png",
                    "projectLink": "https://github.com/EdwardsLabUSU/CodeProcess-API",
                    "technologies": ["JS", "Python", "Visualization", "Computing Education", "Data Analysis"]
                },
                {
                    "id": 4,
                    "name": "GoConfigure",
                    "description": "A simple application built on golang that can be used to configure multiple servers at the same time using SSH.",
                    "detailView": false,
                    "thumbnail": "",
                    "projectLink": "https://github.com/rajshrestha86/go-configure",
                    "technologies": ["GO", "gRPC", "SSH", "Go-routines"]
                },
                {
                    "id": 5,
                    "name": "Police Brutality",
                    "description": "In this project, we analyze the events after George Floyd’s death. The protests and riots across the United States and sentiments of news articles of three different news sources that have different political leaning. We will see how these media reacted after Floyd’s death and see the effect of media bias on the sentiments of news for #BlackLive… ",
                    "detailView": false,
                    "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/police-brutality-data-analysis/master/output/cnn-word-map.png",
                    "projectLink": "https://github.com/rajshrestha86/police-brutality-data-analysis",
                    "technologies": ["Python", "Data Science", "NLP", "Web Crawler", "Visualization"]
                },
                {
                    "id": 6,
                    "name": "Class Schedule APK",
                    "description": "This is the application that I built when I was learning android studio and JAVA in my undergrad. The application is built to keep track of my class schedule and assignments as I would constantly forget about my class schedule and assignment deadlines. The app uses sqlite database to store the courses and assignments. Here are some of the screenshots of the application. This repo includes the source code and released version of the application. The application was built on old android stucio with a target sdk version of 21. In order to run this code on latest version of android studio migration should be done.",
                    "detailView": false,
                    "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/class-schedule-apk/master/screenshots/home-screen.png",
                    "projectLink": "https://github.com/rajshrestha86/class-schedule-apk",
                    "technologies": ["JAVA", "Android", "Mobile APP", "Design"]
                }
            ],
            'technologies': []
        })
        //
        // axios.get(BASE_URL + "/projects").then((res)=>{
        //     let tech = new Set();
        //     res.data.forEach((val) => {
        //         if(val.technologies !== undefined)
        //             val.technologies.forEach(t => tech.add(t))
        //
        //     });
        //     this.setState({
        //         'data': [
        //             {
        //                 "id": 1,
        //                 "name": "Clusterize",
        //                 "description": "Implementation of K-Means clustering algorithm from scratch. Parallelization of clustering algorithm across multiple nodes using OpenMP and MPI to reduce clustering time on a huge dataset. Also performance analysis of multiple approaches used and their comparison. ",
        //                 "detailView": false,
        //                 "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/kmeans-clusterize/master/img/corr-map.png",
        //                 "projectLink": "https://github.com/rajshrestha86/kmeans-clusterize",
        //                 "technologies": ["C", "KMeans", "MPI", "CUDA"]
        //             },
        //
        //             {
        //                 "id": 2,
        //                 "name": "MPI Histogram",
        //                 "description": "A program developed using MPI for distributed computation of Histogram for large data and their performance anaysis on multi-core systems. The program is written in C and uses SPMD architecture to fork processes and compute histogram.",
        //                 "detailView": false,
        //                 "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/kmeans-clusterize/master/img/corr-map.png",
        //                 "projectLink": "https://github.com/rajshrestha86/mpi-histo",
        //                 "technologies": ["C", "Distributed Computing", "MPI", "HPC"]
        //             },
        //             {
        //                 "id": 3,
        //                 "name": "CodeProcess",
        //                 "description": "CodeProcess is a software visualization tool that is designed to give the viewer an immediate assessment of the general characteristics of the process used to develop a piece of software. It features inter-active controls for the user to analyze details of the process. It is a web application developed using Python, D3 and React JS. ",
        //                 "detailView": false,
        //                 "thumbnail": "https://raw.githubusercontent.com/EdwardsLabUSU/CodeProcess-API/main/img/visualization.png",
        //                 "projectLink": "https://github.com/EdwardsLabUSU/CodeProcess-API",
        //                 "technologies": ["JS", "Python", "Visualization", "Computing Education", "Data Analysis"]
        //             },
        //             {
        //                 "id": 4,
        //                 "name": "GoConfigure",
        //                 "description": "A simple application built on golang that can be used to configure multiple servers at the same time using SSH.",
        //                 "detailView": false,
        //                 "thumbnail": "",
        //                 "projectLink": "https://github.com/rajshrestha86/go-configure",
        //                 "technologies": ["GO", "gRPC", "SSH", "Go-routines"]
        //             },
        //             {
        //                 "id": 5,
        //                 "name": "Police Brutality",
        //                 "description": "In this project, we analyze the events after George Floyd’s death. The protests and riots across the United States and sentiments of news articles of three different news sources that have different political leaning. We will see how these media reacted after Floyd’s death and see the effect of media bias on the sentiments of news for #BlackLive… ",
        //                 "detailView": false,
        //                 "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/police-brutality-data-analysis/master/output/cnn-word-map.png",
        //                 "projectLink": "https://github.com/rajshrestha86/police-brutality-data-analysis",
        //                 "technologies": ["Python", "Data Science", "NLP", "Web Crawler", "Visualization"]
        //             },
        //             {
        //                 "id": 6,
        //                 "name": "Class Schedule APK",
        //                 "description": "This is the application that I built when I was learning android studio and JAVA in my undergrad. The application is built to keep track of my class schedule and assignments as I would constantly forget about my class schedule and assignment deadlines. The app uses sqlite database to store the courses and assignments. Here are some of the screenshots of the application. This repo includes the source code and released version of the application. The application was built on old android stucio with a target sdk version of 21. In order to run this code on latest version of android studio migration should be done.",
        //                 "detailView": false,
        //                 "thumbnail": "https://raw.githubusercontent.com/rajshrestha86/class-schedule-apk/master/screenshots/home-screen.png",
        //                 "projectLink": "https://github.com/rajshrestha86/class-schedule-apk",
        //                 "technologies": ["JAVA", "Android", "Mobile APP", "Design"]
        //             }
        //         ],
        //         'technologies': tech
        //     })
        // })
    }

    getCards(){
        return this.state.data.map((each) => {
            return <Card data={each}/>
        })
    }

    getTechStacks(){

        return [...this.state.technologies].sort((a,b) => a.length - b.length).map((val) => <li className={"inlineLi"}>{val}</li>)
    }


    render() {
        return (
            <div>
                <center>
                <ul style={{
                    textAlign: "center",
                    marginBottom: "30px",
                    marginLeft: "-40px",
                    marginTop: "15px",
                    position: "block",
                    width: "80%"
                }}>
                    {/*{this.getTechStacks()}*/}
                </ul>

                {/*<h1>Project Page</h1>*/}
                    <p>You can view my projects in this section.</p>
                    <div>
                        {this.getCards()}
                    </div>

                </center>

            </div>
        );
    }
}

export default Project;