import React from 'react';
import {Link} from "react-router-dom";
import object_get from "../lib/object_get";
import {animated, Spring} from "react-spring";

class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'active': 'home'
        }

        this.itemClicked = this.itemClicked.bind(this);

    }


    itemClicked(e, item){
        console.log("Link has been clicked....", item);
        this.setState({'active': item});
        console.log(this.state)
    }

    render() {
        const page = this.props.location;
        console.log("Called render....");

        return (
            <animated.div style={{
                position: "fixed",
                // textAlign: "right",
                // height: "100px",
                width: "100%",
                background: "white",
                "z-index": "1",
                marginTop: "0px",
                top: 0,
                left: 0,
                boxShadow: "0 0 20px rgba(0,0,0,.12)",
                ...this.props.style

            }} after={{
                // position: "fixed"
            }}>

                        <ul id={'menu-bar'} >
                            {this.props.menu.map((item) => (
                                <li id='menu-item'>
                                    <Link to={'/'+object_get(item, 'url', item.key)} id='menu-item-link'
                                          className={item.key === this.state.active.toString() ? 'active' : null}
                                          onClick={(e)=>this.itemClicked(e, item.key)} >
                                        {item.name}
                                        {/*<img id = 'menu-img' src={'./svg/menu-'+item.toLowerCase()+'.svg'} height={'80px'} width={'120px'}/>*/}
                                    </Link>
                                </li>
                            ))}


                        </ul>


            </animated.div>
        )
    }
}


export default NavBar;