import React from "react";
import {Row, Col, Container} from "react-bootstrap";
class ExperienceSection extends React.Component{

    constructor(props) {
        super(props);
        this.getExperiences = this.getExperiences.bind(this);
    }

    getExperiences(){
        return this.props.data.positions.map((each, index) =>
                <Row>
                    <Row>
                        <Col>
                            <h3 style={{
                                textAlign: "left",
                                fontStyle: "italic"
                            }}>{each.post}</h3>
                        </Col>
                        <Col>
                            <h3 style={{
                                textAlign: "right",
                                fontStyle: "italic",
                                // marginRight: "40px"
                            }}>{each.start} - {each.end}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul style={{
                                textAlign: "justify",
                                width: "100",
                                fontSize: "30px",
                                fontStyle: "italic",
                                // border: "1px solid"

                            }}>
                                {each.tasks.map((task => <li style={{

                                }}>{task}</li>))}
                            </ul>
                            {index < this.props.data.positions.length - 1 || this.props.lastDivider? <hr/>: <div/>}
                        </Col>
                    </Row>

                </Row>
        )
    }

    render() {
        return <Container  fluid style={{
            marginLeft: "40px",
            marginRight: "40px",
            width: "100%",
            // marginBottom: "40px"
        }}>
            <Row>
                    <Col>
                        <h2 style={{
                            // float: "left",
                            // width: "50%",
                            textAlign: "left"
                        }}>{this.props.data.inst}</h2>
                    </Col>
                   <Col>
                       <h2 style={{
                           // float: "left",
                           textAlign: "right",
                           marginRight: "40px"
                       }}>{this.props.data.address}</h2>
                   </Col>
            </Row>
            {this.getExperiences()}
        </Container>
    }
}

export default ExperienceSection;