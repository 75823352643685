import React from "react";
import {Row, Col, Container} from "react-bootstrap";
class EducationSection extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return <Container  fluid style={{
            marginLeft: "40px",
            marginRight: "40px",
            width: "100%",
            marginBottom: "40px"
        }}>
            <Row>
                    <Col>
                        <h2 style={{
                            // float: "left",
                            // width: "50%",
                            textAlign: "left"
                        }}>{this.props.data.inst}</h2>
                    </Col>
                   <Col>
                       <h2 style={{
                           // float: "left",
                           textAlign: "right",
                           marginRight: "40px"
                       }}>{this.props.data.address}</h2>
                   </Col>
            </Row>
            <Row>
                <Col>
                    <h3 style={{
                        textAlign: "left",
                        fontStyle: "italic"
                    }}>{this.props.data.degree}</h3>
                </Col>
                <Col>
                    <h3 style={{
                        textAlign: "right",
                        fontStyle: "italic",
                        marginRight: "40px"
                    }}>{this.props.data.start} - {this.props.data.graduation}</h3>
                </Col>
            </Row>

        </Container>
    }
}

export default EducationSection;